import { blue, grey } from "@mui/material/colors";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { sizes } from "./sizes";

export const themeTypography = (): Record<string, CSSProperties> => {
  const size = sizes();
  return {
    languageButton: {
      border: "none",
      borderRadius: 0,
      height: "48px",
      textTransform: "uppercase",
      paddingLeft: "14px",
      display: "flex",
      justifyContent: "flex-start",
      "@media (max-width: 600px)": {
        fontWeight: 600,
      },
    },
    languageButtonInactive: {
      color: grey[700],
      ":hover": {
        color: grey[800],
        backgroundColor: grey[100],
      },
    },
    languageButtonActive: {
      color: blue[700],
      borderBottom: `2px solid ${blue[700]}`,
      ":hover": {
        color: blue[800],
      },
    },
    inputBase: {
      width: "100%",
      fontSize: size[18],
      lineHeight: "28px",
      padding: "20px 58px 0px 24px",
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
      height: "40px",
      width: "40px",
      margin: "8px",
    },
    outputIcon: {
      fontSize: size[26],
      "@media (max-width: 600px)": {
        fontSize: size[22],
        color: "#fff",
      },
    },
    readingText: {
      fontSize: size[16],
      color: "#313030",
      letterSpacing: "0.5px",
      lineHeight: "23px",
      "@media (max-width: 600px)": {
        lineHeight: "26px",
      },
    },
    readingTitle: {
      fontSize: size[18],
      fontWeight: 600,
      color: "#313030",
      letterSpacing: "0.5px",
      lineHeight: "23px",
      "@media (max-width: 600px)": {
        lineHeight: "26px",
      },
    },
  };
};
