import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { Logo } from "./Logo";
import { RightDrawer } from "./RightMenu";

export const Header: React.FC = () => {
  const theme = useTheme();
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0,0,0,.12)",
      }}
    >
      {/* Maintanence mode message */}
      {theme.mainanenceMode && (
        <Box
          sx={{
            backgroundColor: red[500],
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: grey[50],
              p: "15px 50px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            Translation is unavailable. We are currently in maintanence mode.
          </Typography>
        </Box>
      )}
      <Toolbar sx={{ p: { xs: "6px 4px", sm: "12px 8px" } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Logo />
          <Stack direction="row" spacing={2}>
            <RightDrawer />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
