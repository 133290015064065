import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";

export const About: FC = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
          m: "20px 0 50px",
          p: { xs: "20px", md: "0" },
          pr: { xs: "20px", md: "40%" },
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: theme.size[28],
            color: "#313030",
            pb: "10px",
          }}
        >
          Welcome to Meyabase Translate!
        </Typography>

        {false && (
          <Link
            href="https://contribute.meyabase.com/competition"
            target="_blank"
          >
            <Typography
              sx={{
                border: "1px solid transparent",
                borderRadius: "40px",
                p: "0px 13px",
                borderColor: "#0cc20c",
                borderWidth: "1px",
              }}
            >
              Translate & win up to N$5000 💵 (read more)
            </Typography>
          </Link>
        )}

        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Typography
              sx={{
                fontSize: theme.size[16],
                color: "#313030",
              }}
            >
              Reach out to Axel at
            </Typography>
            <Typography
              component="a"
              href="mailto:hello@meyabase.com"
              sx={{
                fontSize: theme.size[15],
                fontWeight: 600,
                color: "#0072E5",
              }}
            >
              hello@meyabase.com
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Link href="https://www.facebook.com/meyabases" target="_blank">
              <Facebook sx={{ fontSize: 30, color: "#0072E5" }} />
            </Link>

            <Link href="https://twitter.com/meyabase" target="_blank">
              <Twitter sx={{ fontSize: 30, color: "#0072E5" }} />
            </Link>

            <Link href="https://www.instagram.com/meyabase" target="_blank">
              <Instagram sx={{ fontSize: 30, color: "#0072E5" }} />
            </Link>
          </Stack>
        </Stack>

        <Typography
          sx={{
            ...theme.typography.readingText,
          }}
        >
          We have also created{" "}
          <Typography
            component="a"
            href="https://contribute.meyabase.com/"
            target="_blank"
            sx={{
              fontSize: theme.size[15],
              fontWeight: 600,
              color: "#0072E5",
            }}
          >
            Meyabase Contribute
          </Typography>
          , an inclusive and robust language crowdsourcing resource platform. On
          Contribute, users will translate vocabulary-rich sentences into any
          supported indigenous language.
        </Typography>

        <Typography
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Occasionally, we will host numerous competitions with cash prizes and
          rewards. Do follow us on our social media pages.
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: theme.size[19],
              color: "#313030",
              pt: "10px",
            }}
          >
            About Translate
          </Typography>
          <Box
            sx={{
              borderBottom: "1px solid #d8d6d6",
              width: "100%",
              mt: "15px",
            }}
          />
        </Box>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Meyabase Translate is a powerful multilingual neural machine
          translation service developed by Meyabase Platforms. Our goal is to
          make communication easier and more accessible by providing accurate
          and reliable translation services for people all around the world.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          We're dedicated to breaking down language barriers and bringing people
          closer together. That's why we're focused on providing translation
          services for Namibian and Sub-Saharan languages, which are often
          overlooked by other translation services. By doing this, we hope to
          help preserve and promote these languages and cultures.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Our translation service is based on cutting-edge machine learning and
          artificial intelligence technologies. We use state-of-the-art neural
          networks and algorithms to analyze and translate text from one
          language to another. Our algorithms learn from large volumes of data
          and continuously improve their accuracy and reliability over time.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          At Meyabase Translate, we understand that machine translation is not
          perfect, especially for languages with low digital resources, complex
          grammar and syntax. That's why we're constantly working to improve our
          algorithms and add new features to our service. We're committed to
          providing the best possible translation experience for our users.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Our service is currently in beta mode, which means it has some
          limitations. We're working hard to improve the accuracy and speed of
          our translations, and we'll be adding new features soon. If you
          encounter any issues or have any feedback for us, please don't
          hesitate to contact us. We're always happy to hear from our users.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Thank you for choosing Meyabase Translate. We hope our service makes
          your life a little bit easier and helps you connect with people from
          all around the world.
        </Typography>
      </Stack>
    </>
  );
};
