import { blue, grey } from "@mui/material/colors";
import { sizes } from "./sizes";

export const componentStyleOverrides = (): Record<string, any> => {
  const size = sizes();
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          width: "100%",
          minHeight: "48px",
          backgroundColor: "#ffffff",
          "@media (min-width: 600px)": {
            minHeight: "64px",
            padding: "8px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "0 15px",
          fontWeight: 500,
          fontSize: size[14],
          color: "primary",
          backgroundColor: "transparent",
          textTransform: "none" as const,
          borderRadius: "5px",
          height: "36px",
          width: "fit-content",
          border: "1px solid #0000001f",
          "&:hover": {
            backgroundColor: "#0d47a10d",
          },
          "&.Mui-disabled": {
            color: grey[600],
          },
          "& .MuiIconButton-root": {
            stroke: "transparent",
            strokeWidth: "0.3px",
            color: blue[700],
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: size[26],
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            fontWeight: "500 !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: size[13],
          letterSpacing: ".25px",
          color: grey[900],
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: "10px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#525151",
          fontSize: size[14],
          borderBottom: 0,
          wordWrap: "break-word" as const,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: grey[800],
          padding: "24px",
        },
        title: {
          fontSize: size[18],
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: grey[800],
          padding: "0 10px",
          "&.Mui-selected": {
            color: grey[500],
            backgroundColor: grey[100],
            "&:hover": {
              backgroundColor: grey[200],
            },
            "& .MuiListItemIcon-root": {
              color: grey[800],
            },
          },
          "&:hover": {
            backgroundColor: grey[200],
            color: grey[800],
            "& .MuiListItemIcon-root": {
              color: grey[800],
              // fontSize: size[16],
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: grey[800],
          minWidth: "24px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: grey[800],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: grey[800],
          // Below is not working
          "&::placeholder": {
            color: grey[500],
            fontSize: size[14],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          borderRadius: "10px",
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontSize: size[14],
          fontWeight: 600,
          background: "#fff",
          padding: "15.5px 14px",
          borderRadius: "10px",
          "&.MuiInputBase-inputSizeSmall": {
            padding: "12px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          // borderRadius: "10px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root": {
            color: grey[800],
            fontWeight: 600,
            fontSize: size[16],
            '&[data-shrink="false"]': {
              color: grey[600],
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: grey[300],
          },
        },
        mark: {
          backgroundColor: grey[50],
          width: "4px",
        },
        valueLabel: {
          color: grey[800],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[400],
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: grey[800],
          background: grey[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#ffffff",
          background: grey[700],
        },
      },
    },
  };
};
