import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { FC, FocusEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { saveSourceTarget } from "../../http/services/contribute/sourceTarget";
import { LanguageType } from "../../types/general";
import {
  SourceTarget,
  TranslatePrediction,
  TranslateSentence,
} from "../../types/services";
import {
  countedWords,
  getErrorMessage,
  getLimitedWords,
} from "../../utilities/helpers";
import {
  initializeContainerSize,
  removeResizeObserver,
} from "../../utilities/sizeObservers";
import { FeedbackForm } from "./FeedbackForm";
import { Input } from "./Input";
import { Languages } from "./Languages";
import { Output } from "./Output";
import {
  HF_API_TOKEN,
  MODELS,
  MULTI_TRANSLATION_ENDPOINT_URL,
} from "../../utilities/constants";

const localFetch = async ({
  data,
  url,
}: {
  data: TranslateSentence;
  url: string;
}): Promise<TranslatePrediction> => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${HF_API_TOKEN}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  });

  const result = await response.json();
  return result;
};

export const InputOutput: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sl, setSl] = useState<LanguageType>("en");
  const [tl, setTl] = useState<LanguageType>("ng");
  const [input, setInput] = useState<string>("");
  const [output, setOutput] = useState<string>("");
  const [inputWordCount, setInputWordCount] = useState<number>(0);
  // const [outputCount, setOutputCount] = useState<number>(0);
  const [voted, setVoted] = useState<1 | 0 | -1>(0);
  // const [currentUrl, setCurrentUrl] = useState<string>("");
  const [targetId, setTargetId] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const requestTimeout = useRef<NodeJS.Timeout | null>(null);

  const [suggestionOpen, setSuggestionOpen] = useState<boolean>(false);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const theme = useTheme();

  interface Params {
    [key: string]: string;
  }

  interface SaveSourceTargetArgs {
    source: string;
    target: string;
    sourceLang: string;
    targetLang: string;
  }

  const saveInputOutput = async ({
    source,
    target,
    sourceLang,
    targetLang,
  }: SaveSourceTargetArgs): Promise<void> => {
    if (!source || !target || !sourceLang || !targetLang) {
      return;
    }

    const data: SourceTarget = {
      source: { content: source, language: sourceLang },
      target: { content: target, language: targetLang },
    };
    try {
      const response = await saveSourceTarget(data);
      if (response.data.success) {
        // eslint-disable-next-line no-underscore-dangle
        const localTargetId = response.data?.target?._id;
        setTargetId(localTargetId);
      }
    } catch (err) {
      console.log("error saving to contribute:", err);
    }
  };

  const translateInput = async (value: string, retries = 5): Promise<void> => {
    if (!sl || !tl) {
      return;
    }

    if (value.trim().length === 0) {
      setOutput("");
      return;
    }

    setLoading(true);

    if (requestTimeout.current) {
      clearTimeout(requestTimeout.current);
    }

    requestTimeout.current = setTimeout(async () => {
      const text = getLimitedWords(value);
      const modelId = MODELS.find(
        (model) => model.sourceLanguage === sl && model.targetLanguage === tl
      )?.modelId;

      if (!modelId) {
        return;
      }

      const data: TranslateSentence = {
        inputs: text,
        model_id: modelId,
      };

      const url = MULTI_TRANSLATION_ENDPOINT_URL;
      if (!url) {
        setError("Something went wrong");
        return;
      }

      try {
        const prediction = (await localFetch({ url, data })) || [];
        const isArray = Array.isArray(prediction);
        setLoading(false);
        if (isArray) {
          setError("");
          const translation = prediction[0].translation_text;
          setOutput(translation);
          saveInputOutput({
            source: input,
            target: translation,
            sourceLang: sl,
            targetLang: tl,
          });
        } else if (prediction?.error) {
          setError(
            "Server is down due to inactivity and cost saving. Please wait for a few seconds. Retrying..."
          );
          // Wait for 5 seconds before retrying
          setTimeout(() => translateInput(value, retries - 1), 5000);
          console.log("error from server:", prediction.error);
        } else {
          setError("Something went wrong");
        }
      } catch (err) {
        const message = getErrorMessage(err);
        setLoading(false);
        setError(message);
      }
    }, 1000);
  };

  const setDefaultSearchParams = (params: { [key: string]: string }): void => {
    const sourceLanguage = params.sl || "en";
    const targetLanguage = params.tl || "ng";
    const textInput = params.input || input || "";

    translateInput(textInput);

    setSl(sourceLanguage as LanguageType);
    setTl(targetLanguage as LanguageType);
    setInput(textInput);
    setInputWordCount(countedWords(textInput));
    // setCurrentUrl(window.location.href);

    const paramsToSet: Params = {
      sl: sourceLanguage,
      tl: targetLanguage,
    };

    if (textInput) {
      paramsToSet.input = textInput;
    }

    setSearchParams(paramsToSet);
  };

  const startServer = (): void => {
    const url = MULTI_TRANSLATION_ENDPOINT_URL;
    if (!url) {
      return;
    }
    localFetch({ url, data: { inputs: "hello", model_id: "" } });
  };

  useEffect(() => {
    startServer();
    initializeContainerSize();
  }, []);

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    setDefaultSearchParams(params);

    return () => {
      removeResizeObserver();
    };
  }, [searchParams]);

  const swapLanguage = (): void => {
    setSearchParams({ sl: tl, tl: sl, input: output });
  };

  const handleInputChange = (e: FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (value === input) {
      return;
    }

    if (value.length) {
      translateInput(value);
    }

    setInput(value);
    setInputWordCount(countedWords(value));
    setError("");
    setSearchParams({ input: value, sl, tl });
  };

  const clearInput = (): void => {
    setInput("");
    setOutput("");
    setInputWordCount(0);
    setSearchParams({ input: "", sl, tl });
    setError("");
  };

  const handleOpenFeedback = (): void => {
    setFeedbackOpen(true);
  };

  const handleCloseFeedback = (): void => {
    setFeedbackOpen(false);
  };

  return (
    <>
      {error && (
        <Box
          sx={{
            backgroundColor: red[500],
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                color: grey[50],
                p: "15px 50px",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {error}
            </Typography>
            <IconButton
              onClick={(): void => setError("")}
              sx={{
                ...theme.typography.closeButton,
              }}
            >
              <CloseRounded sx={{ fontSize: "22px", color: "white" }} />
            </IconButton>
          </Box>
        </Box>
      )}

      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: { xs: "0px", md: "8px" },
            boxShadow: {
              xs: "0px 4px 4px -4px #0000009c",
              md: "0 1px 4px 0 #0000005e",
            },
          }}
        >
          <Languages sl={sl} tl={tl} swapLanguage={swapLanguage} />
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            alignItems="flex-start"
            id="input-output-container"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Input
              input={input}
              handleInputChange={handleInputChange}
              inputWordCount={inputWordCount}
              clearInput={clearInput}
              suggestionOpen={suggestionOpen}
              loading={loading}
              feedbackOpen={feedbackOpen}
            />
            <Box
              sx={{
                borderLeft: "1px solid #0000001f",
                height: "100%",
                width: "1px",
              }}
            />
            <Output
              voted={voted}
              setVoted={setVoted}
              output={output}
              suggestionOpen={suggestionOpen}
              setSuggestionOpen={setSuggestionOpen}
              targetId={targetId}
              tl={tl}
              feedbackOpen={feedbackOpen}
              loadingText={loading ? "..." : ""}
            />
          </Stack>
        </Stack>

        <ButtonBase
          disableRipple={true}
          onClick={handleOpenFeedback}
          sx={{
            backgroundColor: "transparent",
            p: "0",
            m: "0",
            ":active": {
              backgroundColor: "transparent",
            },
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Typography
            sx={{
              fontStyle: "italic",
              color: grey[700],
              fontSize: "12px",
              fontWeight: 400,
              padding: "7px",
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            Send feedback
          </Typography>
        </ButtonBase>

        <FeedbackForm
          feedbackOpen={feedbackOpen}
          handleClose={handleCloseFeedback}
        />
      </Stack>
    </>
  );
};
