import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Stack,
  IconButton,
  InputBase,
} from "@mui/material";
import { FC, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { createSuggestion } from "../../http/services/contribute/suggestion";
import { Suggestion } from "../../types/services";

interface FeedbackFormProps {
  feedbackOpen: boolean;
  handleClose: () => void;
}

export const FeedbackForm: FC<FeedbackFormProps> = ({
  feedbackOpen,
  handleClose,
}) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>(
    "Your contribution will be used to improve translation quality and may be shown (without identifying you) to other users."
  );
  // const [submitting, setSubmitting] = useState<boolean>(false);

  const [content, setContent] = useState<string>("");

  const handleSubmitFeedback = (): void => {
    setError("");
    setSuccess("");

    if (!content) {
      setError("Content is required");
      return;
    }

    const data: Suggestion = {
      content,
      type: "model-translate-feedback",
    };

    // setSubmitting(true);

    createSuggestion(data)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Thank you for your feedback!");
          setContent("");
          console.log(success);
        } else {
          setError(res.data.message);
          console.log(error);
        }
      })
      .catch((err) => {
        setError(err.message);
      });

    // setSubmitting(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={feedbackOpen}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          sx: {
            maxWidth: { xs: "calc(100% - 30px)", sm: "600px" },
            width: { xs: "calc(100% - 10px)", sm: "calc(100% - 64px)" },
            m: { xs: "32px 0", sm: "32px" },
            p: { xs: "20px", sm: "20px 10px" },
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", p: { xs: "0", sm: "0px 24px 0" } }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[17],
              color: grey[700],
            }}
          >
            Send Feedback
          </Typography>

          <IconButton
            style={{ color: "gray" }}
            onClick={(): void => {
              setContent("");
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Stack>

        <DialogContent sx={{ p: { xs: "20px 0 0 0", sm: "20px" } }}>
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              pb: "5px",
            }}
          >
            <InputBase
              multiline
              minRows={4}
              value={content}
              onChange={(e): void => setContent(e.target.value)}
              onBlur={(e): void => setContent(e.target.value)}
              autoFocus
              sx={{
                ...theme.typography.inputBase,
                border: "1px solid #e0e0e0",
                padding: "10px",
                borderRadius: "10px",
              }}
            />

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%", p: "0" }}
            >
              <Button onClick={handleClose} sx={{ border: "none" }}>
                Cancel
              </Button>
              <Button
                disabled={!content}
                onClick={handleSubmitFeedback}
                sx={{ border: "none" }}
              >
                Submit
              </Button>
            </Stack>

            <Typography
              sx={{
                fontSize: "14px",
                color: grey[700],
                mt: "5px",
              }}
            >
              {error && error}
              {!error && success && success}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
