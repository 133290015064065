import {
  InsertDriveFileOutlined,
  LanguageOutlined,
  Translate,
} from "@mui/icons-material";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

export const TranslationTypeNav: FC = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={1}
    sx={{
      width: "100%",
      height: "100%",
      p: { xs: "25px 15px", md: "15px 0px" },
    }}
  >
    <Button sx={{ backgroundColor: "#0077ff1c", fontSize: "14px" }}>
      <Translate sx={{ mr: "10px", fontSize: "20px" }} />
      Text
    </Button>

    <Tooltip
      placement="bottom"
      title={
        <Typography sx={{ color: grey[50], fontSize: "13px" }}>
          Coming soon!
        </Typography>
      }
    >
      <Button sx={{ fontSize: "14px", cursor: "default" }}>
        <LanguageOutlined sx={{ mr: "10px", fontSize: "20px" }} />
        Websites
      </Button>
    </Tooltip>

    <Tooltip
      placement="bottom"
      title={
        <Typography sx={{ color: grey[50], fontSize: "13px" }}>
          Coming soon!
        </Typography>
      }
    >
      <Button
        sx={{
          display: { xs: "none", md: "flex" },
          fontSize: "14px",
          cursor: "default",
        }}
      >
        <InsertDriveFileOutlined sx={{ mr: "10px", fontSize: "20px" }} />
        Documents
      </Button>
    </Tooltip>
  </Stack>
);
