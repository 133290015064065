import { Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";

export const Policies: FC = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
          m: "20px 0 50px",
          p: { xs: "20px", md: "0" },
          pr: { xs: "20px", md: "40%" },
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: theme.size[28],
            color: "#313030",
            pb: "10px",
          }}
        >
          Service Terms and Policies
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Effective date: 26 February 2023
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          1. Introduction
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Welcome to Meyabase Translate (the "Service"), provided by Meyabase
          Platforms CC ("we", "us", or "our"). These Terms of Service (the
          "Terms") govern your use of the Service, as well as our collection,
          use, and disclosure of your personal information. By accessing or
          using the Service, you agree to be bound by these Terms.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          2. User Conduct
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          You agree to use the Service in compliance with these Terms and all
          applicable laws and regulations. You may not use the Service to:
        </Typography>

        <ul style={{ margin: 0 }}>
          <li>
            Upload, post, or transmit any content that is illegal, harmful,
            offensive, or otherwise objectionable.
          </li>
          <li>
            Violate the rights of any third party, including intellectual
            property rights, privacy rights, or publicity rights.
          </li>
          <li>
            Interfere with the operation of the Service or any other user's use
            of the Service.
          </li>
          <li>
            Attempt to gain unauthorized access to the Service, our servers, or
            any other user's account.
          </li>
          <li>Engage in any other activity that we deem inappropriate.</li>
        </ul>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          3. Intellectual Property
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          All intellectual property rights in the Service, including without
          limitation the Meyabase Translate software, are owned by us or our
          licensors. You may not reproduce, distribute, modify, or create
          derivative works based on the Service or any part thereof.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          4. Privacy Policy
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          We respect your privacy and are committed to protecting your personal
          information. Our Privacy Policy (the "Policy") explains how we
          collect, use, and disclose your personal information in connection
          with the Service. By using the Service, you consent to our collection,
          use, and disclosure of your personal information as described in the
          Policy.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          5. User Content
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          The Service may allow you to upload or otherwise provide content,
          including without limitation text, images, and audio (collectively,
          "User Content"). You retain all rights in your User Content, and you
          grant us a non-exclusive, worldwide, royalty-free, sublicensable, and
          transferable license to use, reproduce, distribute, prepare derivative
          works of, display, and perform your User Content in connection with
          the Service.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          You represent and warrant that you have all necessary rights to grant
          the above license and that your User Content does not infringe the
          rights of any third party.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          6. Disclaimer of Warranties
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          THE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND,
          EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICE WILL BE
          UNINTERRUPTED OR ERROR-FREE.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          7. Limitation of Liability
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION
          WITH THE SERVICE OR THESE TERMS, WHETHER OR NOT WE HAVE BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY TO YOU FOR ALL
          CLAIMS ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR THESE TERMS
          SHALL BE LIMITED TO THE AMOUNT PAID BY YOU FOR THE SERVICE DURING THE
          PRECEDING TWELVE-MONTH PERIOD.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          8. Termination
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          We may terminate these Terms or suspend your access to the Service at
          any time, with or without cause or notice, in our sole discretion.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          9. Governing Law
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          These Terms shall be governed byand construed in accordance with the
          laws of the state of Namibia, without giving effect to any principles
          of conflicts of law. Any legal action or proceeding arising out of or
          relating to these Terms shall be brought exclusively in the courts
          located in Namibia, and each party hereby consents to the jurisdiction
          and venue of such courts.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          10. Severability
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced to the fullest extent under law.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          11. Changes to Terms of Service and Privacy Policy
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          Meyabase Translate reserves the right to update or modify these Terms
          of Service and Privacy Policy at any time and without prior notice. We
          encourage users to periodically review these Terms of Service and
          Privacy Policy to stay informed about any changes that may affect
          their use of our services. By continuing to use Meyabase Translate
          after any changes to these Terms of Service and Privacy Policy, users
          agree to be bound by the updated or modified Terms of Service and
          Privacy Policy.
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          12. Contact Us
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          If you have any questions or concerns about these Terms of Service and
          Privacy Policy or the practices of Meyabase Translate, please contact
          us at{" "}
          <Typography
            component="a"
            href="mailto:hello@meyabase.com"
            sx={{
              fontSize: theme.size[16],
              color: "#0072E5",
            }}
          >
            hello@meyabase.com
          </Typography>
          .
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingTitle,
          }}
        >
          13. Entire Agreement
        </Typography>

        <Typography
          component="div"
          sx={{
            ...theme.typography.readingText,
          }}
        >
          These Terms of Service and Privacy Policy constitute the entire
          agreement between Meyabase Platforms CC and its users, and supersede
          all prior agreements and understandings, whether written or oral,
          relating to the subject matter of these Terms of Service and Privacy
          Policy.
        </Typography>
      </Stack>
    </>
  );
};
