import { LanguageType } from "../types/general";
import { inputWordLimit, languages } from "./constants";

export const handleCopyLink = (elementId: string): void => {
  // Get the text field
  const copyText = document.getElementById(elementId) as HTMLInputElement;

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);
};

export const getLanguage = (iso: LanguageType): string | undefined =>
  languages.find((language) => language.iso === iso)?.label;

export const countedWords = (value: string): number =>
  value.trim().split(/\s+/).length;

export const getLimitedWords = (value: string): string => {
  const words = value.trim().split(/\s+/);
  return words.slice(0, inputWordLimit).join(" ");
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }

  return JSON.stringify(error);
};
