import { Box, useTheme } from "@mui/material";
import { FC } from "react";
import { InputOutput } from "../components/InputOutput";
import { TranslationTypeNav } from "../components/TranslationTypeNav";

export const Home: FC = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #0000001f",
          height: theme.mainanenceMode ? "225px" : "162px",
          overflow: "hidden",
          width: "100%",
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      <TranslationTypeNav />
      <InputOutput />
    </>
  );
};
