import { createTheme } from "@mui/material/styles";
import { Theme } from "@emotion/react";
import { componentStyleOverrides } from "./compStyleOverride";
import { themeTypography } from "./typography";
import { sizes } from "./sizes";
import { MAINTENANCE_MODE } from "../utilities/constants";

declare module "@mui/material/styles" {
  interface Theme {
    colors: { [key: string]: string };
    size: { [key: number]: string };
    borderRadius: string;
    mainanenceMode: boolean;
  }

  interface TypographyVariants {
    languageButton: React.CSSProperties;
    languageButtonActive: React.CSSProperties;
    languageButtonInactive: React.CSSProperties;
    inputBase: React.CSSProperties;
    closeButton: React.CSSProperties;
    outputIcon: React.CSSProperties;
    readingText: React.CSSProperties;
    readingTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mainContent?: React.CSSProperties;
    desktopMenuItem?: React.CSSProperties;
    dropdownMenuContainer?: React.CSSProperties;
    mainMenu?: React.CSSProperties;
    icon?: React.CSSProperties;
    redhat?: string;
    lato?: string;
    poppins?: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    mainContent: true;
    desktopMenuItem: true;
    dropdownMenuContainer: true;
    mainMenu: true;
    icon: true;
    redhat: true;
    lato: true;
    poppins: true;
  }
}

export const theme = (): Theme => {
  const themeOption = {
    paper: "#ffffff",
    backgroundDefault: "#ffffff",
    darkTextPrimary: "#ffffff",
    darkTextSecondary: "#ffffff",
    textDark: "#ffffff",
    menuSelected: "#ffffff",
    menuSelectedBack: "#ffffff",
    divider: "#ffffff",
  };

  const themeOptions = {
    palette: { ...themeOption },
    typography: themeTypography(),
    size: sizes(),
    mainanenceMode: MAINTENANCE_MODE === "true",
  };

  const myTheme = createTheme(themeOptions);
  myTheme.components = componentStyleOverrides();

  return myTheme;
};
