import { AxiosResponse } from "axios";
import { contributeAxios } from "../..";
import { SourceTarget, VoteSourceTarget } from "../../../types/services";

export const saveSourceTarget = (
  data: SourceTarget
): Promise<AxiosResponse<any>> => contributeAxios.post(`/source-target`, data);

export const voteModelTranslation = (
  data: VoteSourceTarget
): Promise<AxiosResponse<any>> =>
  contributeAxios.post(`/vote-model-translation`, data);
