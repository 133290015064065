import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useState } from "react";
import { FeedbackForm } from "../InputOutput/FeedbackForm";

export const RightDrawer: FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const handleOpenFeedback = (): void => {
    setFeedbackOpen(true);
  };

  const handleCloseFeedback = (): void => {
    setFeedbackOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(): void => setOpenDrawer(true)}
        size="large"
        sx={{ m: "0 4px" }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={(): void => setOpenDrawer(false)}
      >
        <Box
          sx={{ width: { xs: "264px", md: "280px" } }}
          role="presentation"
          onClick={(): void => setOpenDrawer(false)}
          onKeyDown={(): void => setOpenDrawer(false)}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <Typography
              component="div"
              sx={{
                flexGrow: 1,
                fontSize: "19px",
                color: grey[700],
                fontWeight: 500,
                p: "16px",
                cursor: "pointer",
              }}
            >
              Meyabase Translate
            </Typography>

            <List sx={{ width: "100%" }}>
              <ListItem sx={{ p: "0" }}>
                <ListItemButton
                  component="a"
                  href="about"
                  sx={{ height: "48px", p: "4px 4px 4px 48px" }}
                >
                  <ListItemText primary="About Meyabase Translate" />
                </ListItemButton>
              </ListItem>
            </List>

            <Divider
              sx={{ width: "100%", borderBottom: "1px rgba(0,0,0,.12) solid" }}
            />

            <List sx={{ width: "100%" }}>
              <ListItem sx={{ p: "0" }}>
                <ListItemButton
                  component="a"
                  href="policies"
                  sx={{ height: "48px", p: "4px 4px 4px 48px" }}
                >
                  <ListItemText primary="Privacy & Terms" />
                </ListItemButton>
              </ListItem>

              <ListItem sx={{ p: "0" }}>
                <ListItemButton
                  component="a"
                  href="help"
                  sx={{ height: "48px", p: "4px 4px 4px 48px" }}
                >
                  <ListItemText primary="Help" />
                </ListItemButton>
              </ListItem>

              <ListItem sx={{ p: "0" }}>
                <ListItemButton
                  onClick={handleOpenFeedback}
                  sx={{ height: "48px", p: "4px 4px 4px 48px" }}
                >
                  <ListItemText primary="Send Feedback" />
                </ListItemButton>
              </ListItem>

              <ListItem sx={{ p: "0" }}>
                <ListItemButton
                  component="a"
                  href="https://meyabase.com/"
                  target="_blank"
                  sx={{ height: "48px", p: "4px 4px 4px 48px" }}
                >
                  <ListItemText primary="About Meyabase" />
                </ListItemButton>
              </ListItem>
            </List>
          </Stack>
        </Box>
      </Drawer>

      <FeedbackForm
        feedbackOpen={feedbackOpen}
        handleClose={handleCloseFeedback}
      />
    </>
  );
};
