import { Box, Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export const Logo: React.FC = () => (
  <Link href="/" underline="none">
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <img
        src="meyabase-logo.png"
        alt="Meyabase Logo"
        width={30}
        height={30}
        style={{ borderRadius: "6px", margin: "0 10px" }}
      />

      <Typography
        component="div"
        sx={{
          flexGrow: 1,
          fontSize: "19px",
          color: grey[700],
          fontWeight: 500,
        }}
      >
        Meyabase Translate
      </Typography>

      <Box
        sx={{
          color: "#fff",
          fontSize: "12px",
          backgroundColor: grey[700],
          padding: "0px 10px",
          borderRadius: "8px",
          ml: "6px",
          mb: "-3px",
        }}
      >
        beta
      </Box>
    </Stack>
  </Link>
);
