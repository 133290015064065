import { Box, Stack } from "@mui/material";
import { Header } from "../header";

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box sx={{ width: "100%", height: "100%" }}>
    <Header />

    <Stack
      component="main"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: { xs: "0", md: "0 85px" },
        transition: ".6s all cubic-bezier(0.4,0,0.2,1)",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </Stack>
  </Box>
);
