import { Model } from "../types/general";

// Config
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE;

// Contribute API
export const CONTRIBUTE_API_BASE_URL = process.env.REACT_APP_CONTRIBUTE_API_URL;
export const CONTRIBUTE_AUTHORIZED_ACCESS_SECRET =
  process.env.REACT_APP_CONTRIBUTE_AUTHORIZED_ACCESS_SECRET;

// Translate API
export const MULTI_TRANSLATION_ENDPOINT_URL =
  process.env.REACT_APP_MULTI_TRANSLATION_ENDPOINT_URL;
export const HF_API_TOKEN = process.env.REACT_APP_HF_API_TOKEN;

export const MODELS: Model[] = [
  {
    sourceLanguage: "en",
    targetLanguage: "ng",
    modelId: "meyabase/en-ng-translation",
  },
  {
    sourceLanguage: "en",
    targetLanguage: "kj",
    modelId: "meyabase/ng-en-translation",
  },
];

export const languages = [
  { label: "English", iso: "en" },
  { label: "Oshindonga", iso: "ng" },
  { label: "Oshikwanyama", iso: "kj" },
  { label: "Otjiherero", iso: "hz" },
  { label: "RuKwangali", iso: "kwn" },
  { label: "Khoekhoegowab", iso: "khi" },
  { label: "siLozi", iso: "loz" },
];

export const inputWordLimit = 230;
