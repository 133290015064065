import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MainLayout } from "./components/mainLayout";
import { theme } from "./theme";
import { About } from "./views/About";
import { Home } from "./views/Home";
import { Policies } from "./views/Policies";

export const App: React.FC = () => (
  <ThemeProvider theme={theme()}>
    <CssBaseline />
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/help" element={<Navigate to="/about" replace />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  </ThemeProvider>
);
