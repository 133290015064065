import axios from "axios";
import {
  CONTRIBUTE_API_BASE_URL,
  CONTRIBUTE_AUTHORIZED_ACCESS_SECRET,
  HF_API_TOKEN,
} from "../utilities/constants";

export const contributeAxios = axios.create({
  baseURL: CONTRIBUTE_API_BASE_URL,
  headers: {
    Authorization: CONTRIBUTE_AUTHORIZED_ACCESS_SECRET,
    "Content-type": "application/json",
  },
});

export const translateAxios = axios.create({
  headers: {
    Authorization: `Bearer ${HF_API_TOKEN}`,
    "Content-type": "application/json",
  },
});
