import {
  CheckCircle,
  CloseRounded,
  ContentCopyRounded,
  Share,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey, lightGreen } from "@mui/material/colors";
import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { createGuestTarget } from "../../../http/services/contribute/target";
import { VotedType } from "../../../types/general";
import { GuestTarget } from "../../../types/services";
import { handleCopyLink } from "../../../utilities/helpers";
import { Rate } from "./Rate";

interface OutputProps {
  voted: VotedType;
  setVoted: Dispatch<SetStateAction<VotedType>>;
  output: string;
  suggestionOpen: boolean;
  setSuggestionOpen: Dispatch<SetStateAction<boolean>>;
  targetId: string;
  tl: string;
  feedbackOpen: boolean;
  loadingText: string;
}

export const Output: FC<OutputProps> = ({
  voted,
  setVoted,
  output,
  suggestionOpen,
  setSuggestionOpen,
  feedbackOpen,
  targetId,
  tl,
  loadingText,
}) => {
  const theme = useTheme();
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const [shareLinkText, setShareLinkText] = useState<string>(
    "Copy link for this translation"
  );

  const [copiedContent, setCopiedContent] = useState<boolean>(false);
  const [copiedContentText, setCopiedContentText] =
    useState<string>("Copy translation");

  const [suggestion, setSuggestion] = useState<string>("");

  useEffect(() => {
    if (output) {
      setSuggestion(output);
    }
  }, [output]);

  // Delay after which the copied message disappears
  const delayCopiedLink = (): void => {
    handleCopyLink("copy-translation-link");
    setCopiedLink(true);
    setShareLinkText("Link copied!");

    setTimeout(() => {
      setCopiedLink(false);
      setShareLinkText("Copy link for this translation");
    }, 3000);
  };

  const delayCopiedContent = (): void => {
    handleCopyLink("copy-translation-content");
    setCopiedContent(true);
    setCopiedContentText("Translation copied!");

    setTimeout(() => {
      setCopiedContent(false);
      setCopiedContentText("Copy translation");
    }, 3000);
  };

  const handleSuggestionClose = (): void => {
    setSuggestionOpen(false);
    setSuggestion(output);
  };

  const handleSubmitSuggestion = (): void => {
    if (!suggestion || !targetId || !tl) return;
    if (suggestion === output) return;

    const data: GuestTarget = {
      content: suggestion,
      targetId,
      language: tl,
    };

    createGuestTarget(data)
      .then(() => {
        setSuggestionOpen(false);
        setSuggestion("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuggestionChange = (
    event: FocusEvent<HTMLInputElement>
  ): void => {
    setSuggestion(event.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      id="output-container"
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: { xs: "#dd5914", md: output ? "#f5f5f5" : "#fff" },
        borderBottomRightRadius: { xs: "0px", md: "8px" },
        pb: "1px",
        position: "relative",
        display: { xs: output || loadingText ? "flex" : "none", md: "flex" },
      }}
    >
      <Box
        component="div"
        id="text-output"
        sx={{
          width: "100%",
          padding: !output && !loadingText ? "0px" : "20px 58px 20px 24px",
          display: suggestionOpen ? "none" : "block",
          minHeight: "88px",
          "@media (min-width: 600px)": {
            minHeight: "112px",
          },
        }}
      >
        {output && (
          <Typography
            sx={{
              fontSize: { xs: "23px", md: "18px" },
              color: { xs: "#fff", md: "#000" },
              display: "inline",
            }}
          >
            {output}
          </Typography>
        )}
        {loadingText && (
          <span className="loading-dots" style={{ display: "inline" }}>
            .
          </span>
        )}
      </Box>

      <Box
        sx={{
          position: "relative",
          display: suggestionOpen ? "flex" : "none",
          width: "100%",
        }}
      >
        <InputBase
          multiline
          minRows={2}
          value={suggestion}
          onChange={handleSuggestionChange}
          onBlur={handleSuggestionChange}
          id="text-suggestion-input"
          inputRef={(e): any => {
            // if mobile, do not focus
            if (window.innerWidth < 600) {
              return null;
            }

            if (feedbackOpen) {
              return null;
            }
            return e && e.focus();
          }}
          onFocus={(e): void => {
            if (feedbackOpen) {
              return;
            }

            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            );
          }}
          inputProps={{
            sx: {
              "@media (max-width: 600px)": {
                color: "#fff",
                fontSize: "23px",
              },
            },
          }}
          sx={{
            ...theme.typography.inputBase,
          }}
        />

        <IconButton
          onClick={(): void => setSuggestion("")}
          sx={{
            ...theme.typography.closeButton,
          }}
        >
          <CloseRounded
            sx={{
              fontSize: "22px",
              "@media (max-width: 600px)": {
                color: "#fff",
              },
            }}
          />
        </IconButton>
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ width: "100%", p: !suggestionOpen ? "0px" : "0 15px 10px" }}
      >
        {suggestionOpen && (
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              pb: "5px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%", p: "0" }}
            >
              <Button
                onClick={handleSuggestionClose}
                sx={{
                  border: "none",
                  "@media (max-width: 600px)": {
                    color: "#fff",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={suggestion === output}
                onClick={handleSubmitSuggestion}
                sx={{
                  border: "none",
                  "@media (max-width: 600px)": {
                    color: "#fff",
                  },
                }}
              >
                Submit
              </Button>
            </Stack>

            <Typography
              sx={{
                fontSize: "14px",
                color: grey[700],
                mt: "5px",
                "@media (max-width: 600px)": {
                  color: "#fff",
                },
              }}
            >
              Your contribution will be used to improve translation quality and
              may be shown (without identifying you) to other users.
            </Typography>
          </Stack>
        )}
        {!suggestionOpen && output && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", p: "5px" }}
          >
            <Tooltip title={copiedContentText} placement="bottom">
              <IconButton onClick={delayCopiedContent}>
                {!copiedContent && (
                  <ContentCopyRounded
                    sx={{
                      ...theme.typography.outputIcon,
                    }}
                  />
                )}
                {copiedContent && (
                  <CheckCircle
                    sx={{
                      ...theme.typography.outputIcon,
                      color: { xs: lightGreen[200], md: lightGreen[800] },
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>

            <input
              style={{ display: "none" }}
              type="text"
              onChange={(): void => {}}
              value={output}
              id="copy-translation-content"
            />

            <Rate
              voted={voted}
              setVoted={setVoted}
              setSuggestionOpen={setSuggestionOpen}
              targetId={targetId}
            />

            <Tooltip title={shareLinkText} placement="bottom">
              <IconButton onClick={delayCopiedLink}>
                {!copiedLink && (
                  <Share sx={{ ...theme.typography.outputIcon }} />
                )}
                {copiedLink && (
                  <CheckCircle
                    sx={{
                      ...theme.typography.outputIcon,
                      color: lightGreen[800],
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>

            <input
              style={{ display: "none" }}
              type="text"
              onChange={(): void => {}}
              value={window.location.href}
              id="copy-translation-link"
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
