const containerSize = (): void => {
  const root = document.documentElement;
  const textInput = document.getElementById("text-input");
  const textOutput = document.getElementById("text-output");
  const suggestionInput = document.getElementById("text-suggestion-input");

  const mobile = !(root.offsetWidth > 600);

  let suggestionHeight = 0;
  if (suggestionInput) {
    suggestionHeight = suggestionInput.offsetHeight + 50;
  }
  if (textInput && textOutput) {
    const height = Math.max(
      textInput.offsetHeight,
      textOutput.offsetHeight,
      suggestionHeight
    );

    if (height) {
      const inputContainer = document.getElementById("input-container");
      const outputContainer = document.getElementById("output-container");
      const inputOutputContainer = document.getElementById(
        "input-output-container"
      );

      if (inputContainer) {
        if (mobile) {
          inputContainer.style.maxHeight = `auto`;
        } else {
          inputContainer.style.maxHeight = `${height + 53 + 20}px`;
        }
      }

      if (outputContainer) {
        if (mobile) {
          outputContainer.style.height = `auto`;
        } else {
          outputContainer.style.height = `${height + 53 + 22}px`;
        }
      }

      if (inputOutputContainer) {
        if (mobile) {
          inputOutputContainer.style.height = `auto`;
        } else {
          inputOutputContainer.style.height = `${height + 53 + 22}px`;
        }
      }
    }
  }
};

export const initializeContainerSize = (): void => {
  containerSize();
  const textInput = document.getElementById("text-input");
  const textOutput = document.getElementById("text-output");
  const suggestionInput = document.getElementById("text-suggestion-input");
  if (textInput) new ResizeObserver(containerSize).observe(textInput);
  if (textOutput) new ResizeObserver(containerSize).observe(textOutput);
  if (suggestionInput)
    new ResizeObserver(containerSize).observe(suggestionInput);
};

export const removeResizeObserver = (): void => {
  const textInput = document.getElementById("text-input");
  const textOutput = document.getElementById("text-output");
  const suggestionInput = document.getElementById("text-suggestion-input");
  if (textInput) new ResizeObserver(containerSize).unobserve(textInput);
  if (textOutput) new ResizeObserver(containerSize).unobserve(textOutput);
  if (suggestionInput)
    new ResizeObserver(containerSize).unobserve(suggestionInput);
};
