import { SwapHorizRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { FC } from "react";
import { LanguageType } from "../../types/general";
import { getLanguage } from "../../utilities/helpers";

interface LanguageProps {
  sl: LanguageType;
  tl: LanguageType;
  swapLanguage: () => void;
}

export const Languages: FC<LanguageProps> = ({ sl, tl, swapLanguage }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
        borderTop: { xs: "1px solid #0000001f", md: "none" },
        borderBottom: "1px solid #0000001f",
      }}
    >
      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Button
          sx={{
            ...theme.typography.languageButton,
            ...theme.typography.languageButtonActive,
            "@media (max-width: 600px)": {
              border: `0px solid ${blue[700]}`,
            },
          }}
        >
          {getLanguage(sl)}
        </Button>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "40px", opacity: 1 }}
      >
        <Tooltip
          placement="bottom"
          // title=""
          title={
            <Typography sx={{ color: grey[50], fontSize: "13px" }}>
              Swap languages
            </Typography>
          }
        >
          <IconButton onClick={swapLanguage}>
            <SwapHorizRounded sx={{ fontSize: "24px" }} />
            {/* <ArrowRightAltRounded sx={{ fontSize: "24px" }} /> */}
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Button
          sx={{
            ...theme.typography.languageButton,
            ...theme.typography.languageButtonActive,
            "@media (max-width: 600px)": {
              border: `0px solid ${blue[700]}`,
            },
          }}
        >
          {getLanguage(tl)}
        </Button>
      </Stack>
    </Stack>
  );
};
