import { CloseRounded } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, FocusEvent, useEffect } from "react";
import { inputWordLimit } from "../../utilities/constants";

interface InputProps {
  input: string;
  handleInputChange: (event: FocusEvent<HTMLInputElement>) => void;
  inputWordCount: number;
  clearInput: () => void;
  suggestionOpen: boolean;
  feedbackOpen: boolean;
  loading: boolean;
}

export const Input: FC<InputProps> = ({
  input,
  handleInputChange,
  inputWordCount,
  clearInput,
  suggestionOpen,
  feedbackOpen,
}) => {
  const theme = useTheme();
  useEffect(() => {
    // changeHeight();
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      id="input-container"
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        "@media (max-width: 600px)": {
          maxHeight: "100% !important",
        },
      }}
    >
      <InputBase
        multiline
        // minRows={4}
        value={input}
        onChange={handleInputChange}
        onBlur={handleInputChange}
        placeholder="Enter text"
        id="text-input"
        inputRef={(e): any => {
          // if mobile, do not focus
          if (window.innerWidth < 600) {
            return null;
          }

          if (suggestionOpen || feedbackOpen) {
            return null;
          }
          return e && e.focus();
        }}
        onFocus={(e): void => {
          // if mobile, do not focus
          if (window.innerWidth < 600) {
            return;
          }

          if (suggestionOpen || feedbackOpen) {
            return;
          }

          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: { xs: "#000", sm: "transparent" },
              fontSize: "23px",
              "@media (min-width: 600px)": {
                fontSize: theme.size[18],
              },
            },
            minHeight: "65px",
            fontSize: "23px",
            "@media (min-width: 600px)": {
              minHeight: "112px",
              fontSize: "18px",
            },
          },
        }}
        sx={{
          ...theme.typography.inputBase,
          p: { xs: "16px 50px 16px 16px", sm: "20px 58px 0px 24px" },
        }}
      />

      <IconButton
        onClick={clearInput}
        sx={{
          ...theme.typography.closeButton,
        }}
      >
        <CloseRounded sx={{ fontSize: "22px" }} />
      </IconButton>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ width: "100%", pb: "5px" }}
      >
        {false && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              width: "100%",
              p: { xs: "5px 15px", md: "15px" },
              height: { xs: "auto", md: "53px" },
            }}
          >
            <CircularProgress
              sx={{
                color: grey[500],
                width: {
                  xs: `${theme.size[16]} !important`,
                  md: `${theme.size[22]} !important`,
                },
                height: {
                  xs: `${theme.size[16]} !important`,
                  md: `${theme.size[22]} !important`,
                },
              }}
            />
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            width: "100%",
            p: { xs: "5px 15px", md: "15px" },
            height: { xs: "auto", md: "53px" },
          }}
        >
          <Typography sx={{ fontSize: "12px", color: grey[700] }}>
            {inputWordCount.toLocaleString()}/{inputWordLimit.toLocaleString()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
