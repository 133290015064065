import {
  EditOutlined,
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbsUpDownOutlined,
  ThumbUpAlt,
  ThumbUpOffAlt,
} from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, lightGreen, red } from "@mui/material/colors";
import {
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from "react";
import { voteModelTranslation } from "../../../http/services/contribute/sourceTarget";
import { VotedType } from "../../../types/general";
import { VoteSourceTarget } from "../../../types/services";

interface RateProps {
  voted: VotedType;
  setVoted: Dispatch<SetStateAction<VotedType>>;
  setSuggestionOpen: Dispatch<SetStateAction<boolean>>;
  targetId: string;
}

export const Rate: FC<RateProps> = ({
  voted,
  setVoted,
  setSuggestionOpen,
  targetId,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleVote = (vote: 1 | -1): void => {
    const data: VoteSourceTarget = {
      vote,
      targetId,
    };
    voteModelTranslation(data);
    setVoted(vote);
  };

  return (
    <>
      <Tooltip title="Rate this translation" placement="bottom">
        <IconButton
          ref={anchorRef}
          id="rate-button"
          onClick={handleToggle}
          disableFocusRipple
        >
          {voted === 0 && (
            <ThumbsUpDownOutlined sx={{ ...theme.typography.outputIcon }} />
          )}
          {voted === 1 && (
            <ThumbUpAlt sx={{ ...theme.typography.outputIcon }} />
          )}
          {voted === -1 && (
            <ThumbDownAlt sx={{ ...theme.typography.outputIcon }} />
          )}
        </IconButton>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 0.9,
                maxWidth: 250,
                p: "15px 24px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="rate-popup"
                  aria-labelledby="rate-button"
                  onKeyDown={handleListKeyDown}
                >
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "1.5rem",
                        color: "#6e6c6c",
                        textAlign: "center",
                      }}
                    >
                      Rate this translation
                    </Typography>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      sx={{ width: "100%" }}
                    >
                      <IconButton
                        disabled={voted === 1}
                        onClick={(): void => handleVote(1)}
                        sx={{ border: "0.4px solid #cfcfcf", p: "10px" }}
                      >
                        {voted !== 1 && <ThumbUpOffAlt sx={{}} />}
                        {voted === 1 && (
                          <ThumbUpAlt sx={{ color: lightGreen[700] }} />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={voted === -1}
                        onClick={(): void => handleVote(-1)}
                        sx={{ border: "0.4px solid #cfcfcf", p: "10px" }}
                      >
                        {voted !== -1 && <ThumbDownOffAlt sx={{}} />}
                        {voted === -1 && (
                          <ThumbDownAlt sx={{ color: red[300] }} />
                        )}
                      </IconButton>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#505050",
                      }}
                    >
                      Your feedback will be used to help improve the product
                    </Typography>

                    <Divider
                      sx={{ width: "100%", border: "0.4px solid #cfcfcf" }}
                    />

                    <Button
                      onClick={(): void => setSuggestionOpen(true)}
                      sx={{
                        border: "none",
                        backgroundColor: "#fff",
                        ":hover": {
                          backgroundColor: "#b3cffc4a",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <EditOutlined
                          sx={{
                            fontSize: "20px",
                            color: blue[700],
                            ":hover": { color: blue[900] },
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: blue[700],
                            ":hover": { color: blue[900] },
                          }}
                        >
                          Suggest an edit
                        </Typography>
                      </Stack>
                    </Button>
                  </Stack>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
